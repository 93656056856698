var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner py-2 d-flex justify-content-center"},[_c('b-card',{staticClass:"mb-0 Card-width-1"},[_c('b-card-title',{staticClass:"frogot-password-card-title font-weight-bold mb-3 d-flex align-items-center justify-content-center",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('ChangePassword.Title'))+" ")]),_c('validation-observer',{ref:"changePassword",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('input',{staticClass:"d-none",attrs:{"autocomplete":"username"}}),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"old password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ChangePassword.CurrentPasswordLabel'),"label-for":"account-old-password"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-old-password","name":"old-password","autocomplete":"current-password","type":_vm.passwordFieldTypeOld,"placeholder":_vm.$t('ChangePassword.CurrentPasswordPlaceholder')},model:{value:(_vm.passwordValueOld),callback:function ($$v) {_vm.passwordValueOld=$$v},expression:"passwordValueOld"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"new password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-new-password","label":_vm.$t('ChangePassword.NewPasswordLabel')}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldTypeNew,"autocomplete":"new-password","name":"new-password","placeholder":_vm.$t('ChangePassword.NewPasswordPlaceholder')},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=$$v},expression:"newPasswordValue"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"retype new password","rules":"required|confirmed:new password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-retype-new-password","label":_vm.$t('ChangePassword.RetypeNewPasswordLabel')}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","autocomplete":"new-password","placeholder":_vm.$t('ChangePassword.RetypeNewPasswordPlaceholder')},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] === 'The retype new password field confirmation does not match' ? 'The new password field and retype new password field does not match' : errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('ChangePassword.SaveBtn'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t('ChangePassword.ResetBtn'))+" ")])],1)],1)]}}])})],1)],1),_c('Loader',{attrs:{"show":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }